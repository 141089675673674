#footer {
  border: unset;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
  padding: 0;
  position: relative !important;
}
#footer::before {
  content: "";
  background-image: url(/static/media/edge-footer.f5000a73.png);
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
  position: absolute;
  top: -1px;
  height: 4.5em;
  width: 100%;
}
.footerInner {
  padding: 4em 0 0 0;
  height: 100%;
  background-color: transparent;
  background-image: url(/static/media/wood.57c0f517.png);
  background-size: cover;
  background-repeat: repeat-x;
  background-position: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
}
.footerList {
  margin: 1em 0 !important;
}
.footerList .item {
  color: #fff;
}
@media only screen and (min-width: 600px) {
  .footerInner {
    flex-direction: row;
    flex-wrap: unset;
    align-content: unset;
    align-items: flex-end;
  }
  .footerList {
    margin: unset;
  }
}
