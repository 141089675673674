.header-wrapper {
  background-color: transparent !important;
  background-image: url(/static/media/wood.57c0f517.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: unset !important;
}
.header-wrapper.ui.segment {
  position: relative !important;
}
.header-wrapper::before {
  content: "";
  background-image: url(/static/media/edge-header.a0f23e4c.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: top;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 4em;
  width: 100%;
}
.header-wrapper > .ui.container {
  padding: 0 0 2em 0;
}
.header-wrapper .item {
  color: #000 !important;
}
.header-wrapper .icon {
  fill: #fff !important;
}
.header-wrapper .transparent.input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header-wrapper .transparent.input input::placeholder,
.header-wrapper .transparent.input input {
  color: rgba(255, 255, 255, 0.8);
}
.header-wrapper .transparent.input input:focus::-moz-placeholder {
  color: #ffffff;
}
.header-wrapper .transparent.input input:focus::placeholder,
.header-wrapper .transparent.input input:focus {
  color: #ffffff;
}
.header-wrapper .searchbox.field {
  border-left: 1px solid #fff;
}
.header-wrapper .ui.secondary.pointing.menu .active.item {
  border-color: rgba(255, 255, 255, 0.7);
}
.header-wrapper .ui.secondary.pointing.menu .active.item:hover {
  border-color: #fff;
}
.tools-search-wrapper {
  display: none !important;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #fff;
}
.hamburger.is-active .hamburger-inner:after {
  background-color: #000;
}
@media only screen and (min-width: 990px) {
  .header-wrapper .item {
    color: #fff !important;
  }
}
